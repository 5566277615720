@font-face {
  font-family: "Ginestra";
  src: url("../src/fuentes/ginestra-black-webfont.woff") format("woff");
  /* Agrega otras declaraciones según el formato de tu tipografía */
}

@font-face {
  font-family: "d-din";
  src: url("./fuentes/d-din-webfont.woff") format("woff");
  /* Agrega otras declaraciones según el formato de tu tipografía */
}

@font-face {
  font-family: "d-din-bold";
  src: url("./fuentes/d-din-bold-webfont.woff") format("woff");
  /* Agrega otras declaraciones según el formato de tu tipografía */
}
